import CRUDService from './CRUDService';
import http from '../utils/http';

class FieldService extends CRUDService {
  constructor(path) {
    super(path);
  }

  getFieldsTree(fieldsEntityType, fieldsEntityId) {
    return http({
      url: `api/${this.path}/tree/${fieldsEntityType}/${fieldsEntityId}`,
      method: 'get'
    });
  }
}
export default new FieldService('fields');
