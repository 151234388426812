<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit">
    <DynamicForm
      :level="0"
      :include="include"
      :exclude="exclude"
      :config="config"
      :fields="fieldsTreeFiltered"
      :initial-value="initialValue"
      :value="value"
      :verified="verified"
      @update="onChange"
      @submit="submit">
      <template #controls="{ cancel, submit, disabled }">
        <slot name="controls"><div></div></slot>
      </template>
      <template #field_board>
        <div class="is-relative">
          <div v-if="config.allowMainTableVerification" :class="{ 'shift-left': config.allowMainTableVerification }">
            <LockItem :locked="Boolean(verified['board'])" @toggle="toggleVerified('board') "/>
          </div>
        </div>
        <div class="buttons" v-if="unlinked && !config.readonly">
          <button type="button" class="button is-small" @click="linkAll('board')">Link all</button>
        </div>
      </template>
      <template #field_shareholders>
        <div class="is-relative">
          <div v-if="config.allowMainTableVerification" :class="{ 'shift-left': config.allowMainTableVerification }">
            <LockItem :locked="Boolean(verified['shareholders'])" @toggle="toggleVerified('shareholders')" />
          </div>
        </div>
        <div class="buttons" v-if="unlinked && !config.readonly">
          <button type="button" class="button is-small" @click="linkAll('shareholders')">Link all</button>
        </div>
      </template>
      <template #field_name>
        <button v-if="!hasRole(['BASIC_USER']) && value.name && !config.disabled && !config.readonly && !verified['name']" type="button" class="button is-primary control" @click="searchKboName">{{ $t('contact.searchKbo') }}</button>
        <button v-if="!hasRole(['BASIC_USER']) && value.name && !config.disabled && !config.readonly && !verified['name']" type="button" class="button is-primary control" @click="searchApName">{{ $t('contact.searchAdminPulse') }}</button>
      </template>
      <template #field_vat_number>
        <button v-if="!hasRole(['BASIC_USER']) && value.vat_number && !config.disabled && !config.readonly && !verified['vat_number']" type="button" class="button is-primary" data-tst-button="search-kbo-number" @click="searchKboNumber(null)">{{ $t('contact.searchKbo') }}</button>
        <button v-if="!hasRole(['BASIC_USER']) && value.vat_number && !config.disabled && !config.readonly && !verified['vat_number']" type="button" class="button is-primary" data-tst-button="search-kbo-number" @click="searchApNumber(null)">{{ $t('contact.searchAdminPulse') }}</button>
      </template>
    </DynamicForm>
  </ValidationObserver>
</template>

<script lang="ts">
import { mapGetters, mapMutations, mapState } from 'vuex';
import ContactService from '@/services/ContactService';
import DynamicForm from '@/modules/dynamic-form/components/DynamicForm.vue';
import ContactLinkDialog from '@/modules/dynamic-form/components/ContactLinkDialog.vue';
import { diffRecursive } from '@/utils/diffRecursive';
import { FieldsMixin } from '@/modules/dossier/mixins/FieldsMixin';
import { cloneDeep } from 'lodash';
import LockItem from "@/modules/dynamic-form/components/LockItem.vue";
import {KboMixin} from "@/modules/contact/components/KboMixin";
import {AdminPulseMixin} from "@/modules/contact/components/AdminPulseMixin";

export default {
  name: 'CompanyForm',
  components: {
    DynamicForm, LockItem
  },
  mixins: [ FieldsMixin, KboMixin, AdminPulseMixin ],
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: Object,
      default: undefined
    },
    value: {
      type: Object,
      default: null
    },
    verified: {
      type: Object,
      default: () => ({})
    },
    include: {
      type: Array,
      default: null
    },
    exclude: {
      type: Array,
      default: null
    }
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('blocksave', ['dirty']),
    ...mapGetters({
      user: 'authentication/user'
    }),
    fieldsTreeFiltered() {
      if (['011', '012', '013', '612', '617'].indexOf(this.value.legalform) === -1) {
        return this.fieldsTreeWithoutManager;
      } else {
        return this.fieldsTree;
      }
    },
    isBackOffice() {
      return this.$route.path.startsWith('/admin/');
    },
    unlinked() {
      if (!this.value.board) {
        return false;
      }
      for (const row of this.value.board) {
        if (!row) {
          continue;
        }
        if (row.person?.firstname && !row.person.id) {
          return true;
        }
        if (row.enterprise?.vat_number && !row.enterprise.id) {
          return true;
        }
      }
      return false;
    }
  },

  async created() {
    await this.refreshFields('contact', 'company');
  },

  methods: {
    async validate() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$scrollToErrors();
      }
      return isValid;
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$scrollToErrors();
        // ABORT!!
        return false;
      }

      await this.linkAll('board');
      await this.linkAll('shareholders');

      // const contact = Object.assign({}, this.entity, { user: undefined, organization: undefined });
      this.$emit('submit');
    },

    ...mapMutations('blocksave', ['setDirty', 'setClean']),
    onChange({ data, verified, overwrite_synced_at }) {
      if (data.board) {
        data.board = data.board.filter(representative => {
          if (representative.type !== '10003') {
            return true;
          }

          const director = data.board.find(item => ['10002', '10006', '10007'].indexOf(item.type) > -1 && item.enterprise?.id === representative.enterprise?.id);
          if (!director) {
            return false;
          }

          return true;
        })
      }

      if (this.initialValue) {
        if (diffRecursive(this.initialValue, data) || diffRecursive(data, this.initialValue)) {
          this.setDirty();
        } else {
          this.setClean();
        }
      }
      this.$emit('update', { data, verified, overwrite_synced_at });
    },
    async linkAll(field) {
      if (!this.value[field]) {
        return false;
      }
      const persons = {};
      const enterprises = {};
      for (const row of this.value[field]) {
        if (row.person) {
          if (row.person.firstname && !row.person.id) {
            persons[row.person?.firstname + row.person?.lastname] = row.person;
          } else
          if (row.person.id) {
            console.log('p', row.person);
          }
        }
        if (row.enterprise) {
          if (row.enterprise.vat_number && !row.enterprise.id) {
            enterprises[row.enterprise?.vat_number] = row.enterprise;
          } else
          if (row.enterprise.id) {
            console.log('e', row.person);
          }
        }
      }

      console.log('persons', persons);
      console.log('enterprises', enterprises);

      for (const enterprise of Object.values(enterprises)) {
        await this.link(Object.assign({}, enterprise, { type: 'COMPANY' }));
      }
      for (const person of Object.values(persons)) {
        await this.link(Object.assign({}, person, { type: 'PERSON' }));
      }
      this.$emit('input', cloneDeep(this.value));
    },
    async link(item) {
      const { data } = await ContactService.link(Object.assign({}, item, {
        tenant_id: this.config.tenant_id
      }));
      if (!data) {
        return;
      }

      if (data?.id) {
        for (const row of this.value.board) {
          if (row.person && data.type === 'PERSON') {
            if (row.person.firstname === data.firstname && row.person.lastname === data.lastname) {
              row.person.id = data.id;
              row.person.vat_number = data.vat_number;
              row.person.name = data.name;
              continue;
            }
          }
          if (row.enterprise && data.type === 'COMPANY') {
            if (row.enterprise.vat_number === data.vat_number) {
              row.enterprise.id = data.id;
              row.enterprise.name = data.name;
              continue;
            }
          }
        }
        // this.$emit('input', cloneDeep(this.entity));
        return;
      }

      if (data?.existing) {
        return new Promise<void>((resolve, reject) => {
          this.$buefy.modal.open({
            parent: this,
            component: <any>ContactLinkDialog,
            hasModalCard: true,
            props: {
              existing: data.existing
            },
            events: {
              create: async () => {
                await this.link(Object.assign({}, item, { force: true }));
                resolve();
              },
              link: async (row) => {
                await this.link(row);
                resolve();
              },
              onCancel() {
                reject();
              }
            }
          });
        });
      }
    },
    toggleVerified(fieldName) {
      const verified = cloneDeep(this.verified);
      const value = !verified[fieldName] ? { validated_at: new Date(), validated_by: this.user.email } : undefined;
      verified[fieldName] = value;
      this.$emit('update', { data: this.value, verified: verified });
    }
  }
};
</script>
<style>
.shift-left {
  position: absolute;
  left: 0;
  top: -30px;
}
</style>
