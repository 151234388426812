import FieldService from '@/services/FieldService';
import { cloneDeep } from 'lodash';

export const FieldsMixin = {

  data() {
    return {
      fieldsTree: [],
      fieldsTreeWithoutManager: [],
    }
  },

  methods: {
    async refreshFields(fieldsEntityType, fieldsEntityId) {
      const response = await FieldService.getFieldsTree(fieldsEntityType, fieldsEntityId);
      this.fieldsTree = response.data;
      this.fieldsTreeWithoutManager = cloneDeep(this.fieldsTree)
        .map(row => {
          if (row.name === 'board') {
            const typeField = row.children.find(item => item.name === 'type');
            if (typeField) {
              typeField.options = typeField.options.filter(item => '10006' !== item.value);
            }
          }
          return row;
        });
    },
  }
};
