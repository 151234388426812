import KboService from "@/services/KboService";
import CompanySearchDialog from "@/modules/contact/components/CompanySearchDialog.vue";
import {cloneDeep} from "lodash";

export const KboMixin = {
    methods: {
        async searchKboNumber(vat_number) {
            if (!vat_number) {
                vat_number = this.value.vat_number;
            }
            if (!vat_number) {
                return;
            }
            const result = await KboService.searchByCompany({ number: vat_number });
            const results = result.data.enterprises;
            if (results.length !== 1) {
                this.$alertError(this.$t('common.notFound'));
            } else {
                this.assignKbo(results[0]);
            }
        },
        searchKboName() {
            this.$buefy.modal.open({
                parent: this,
                component: <any>CompanySearchDialog,
                hasModalCard: true,
                props: {
                    value: this.value
                },
                events: {
                    submit: result => {
                        this.searchKboNumber(result.vat_number);
                    }
                }
            });
        },
        assignKbo(result) {
            const currentData = cloneDeep(this.value)
            if (result.name) {
                currentData.name = result.name;
            }
            if (result.vat_number) {
                currentData.vat_number = result.vat_number;
            }
            if (result.address) {
                currentData.address = result.address;
            }
            if (result.address_second) {
                currentData.address_second = result.address_second;
            }
            if (result.city) {
                currentData.city = result.city;
            }
            if (result.country) {
                currentData.country = result.country;
            }
            if (result.postal_code) {
                currentData.postal_code = result.postal_code;
            }
            if (result.yearendmonth) {
                currentData.yearendmonth = result.yearendmonth;
            }
            if (result.yearendday) {
                currentData.yearendday = result.yearendday;
            }
            if (result.annualmeetingmonth) {
                currentData.annualmeetingmonth = result.annualmeetingmonth;
            }

            if (result.incorporationdate) {
                currentData.incorporationdate = result.incorporationdate;
            }

            if (result.legalsituation) {
                currentData.legalsituation = result.legalsituation;
            }
            if (result.status) {
                currentData.status = result.status;
            }

            if (result.legalform) {
                currentData.legalform = result.legalform;
            }
            if (result.enterprise_court) {
                currentData.enterprise_court = result.enterprise_court;
            }
            if (result.contribution) {
                currentData.contribution = result.contribution;
            }
            if (result.court) {
                currentData.court = result.court;
            }

            if (result.board) {
                currentData.board = result.board.map(row => {
                    return row;
                });
            }
            this.onChange({ data: currentData, verified: this.verified, overwrite_synced_at: true });
        }
    }
};
