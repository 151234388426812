<template>
  <div class="modal-card" style="width: 600px">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('contact.existingFound') }}</p>
    </header>
    <section class="modal-card-body">
      <table class="table table-border table-hover is-fullwidth">
        <tbody>
          <tr v-for="(row, idx) in existing" :key="idx">
            <td>
              {{ row.vat_number }}
            </td>
            <td>
              {{ row.name }}
            </td>
            <td>
              <button @click="link(row)" class="button is-small">Link</button>
            </td>
          </tr>
        </tbody>
      </table>
      <button @click="create" class="button is-primary">{{ $t('contact.new') }}</button>
      <button @click="onCancel" class="button">{{ $t('form.cancel') }}</button>
    </section>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    existing: {
      type: Array
    }
  },

  data() {
    return {
      internalValue: {}
    }
  },

  created() {
    if (this.value) {
      this.internalValue = this.value;
    }
  },

  computed: {
  },

  methods: {
    create() {
      this.$emit('create');
      this.$parent.close();
    },
    link(row) {
      this.$emit('link', row);
      this.$parent.close();
    },
    onCancel() {
      this.$parent.close();
    }
  }
};
</script>
