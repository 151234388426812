<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit">
    <DynamicForm
      :level="0"
      :config="config"
      :fields="fieldsTree"
      :initial-value="initialValue"
      :value="value"
      :verified="verified"
      @update="$emit('update', $event)"
      @submit="submit">
      <template #controls="{ cancel, submit, disabled }">
        <slot name="controls"><div></div></slot>
      </template>
    </DynamicForm>
  </ValidationObserver>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import DynamicForm from '@/modules/dynamic-form/components/DynamicForm.vue';
import { FieldsMixin } from '@/modules/dossier/mixins/FieldsMixin';

export default {
  name: 'PersonForm',
  components: {
    DynamicForm
  },
  mixins: [ FieldsMixin ],

  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: Object,
      default: undefined
    },
    value: {
      type: Object,
      default: null
    },
    verified: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('blocksave', ['dirty']),
    isBackOffice() {
      return this.$route.path.startsWith('/admin/');
    }
  },

  async created() {
    await this.refreshFields('contact', 'person');
  },

  methods: {
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$scrollToErrors();
        // ABORT!!
        return false;
      }
      this.$emit('submit');
    },
    ...mapMutations('blocksave', ['setDirty', 'setClean'])
  }
};
</script>
